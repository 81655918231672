/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
.tabilao{
    margin-left:10px;
    width: 100%;
  }
  
  .table-style  {
    border-collapse: collapse;
    box-shadow: 0 5px 50px rgba(0,0,0,0.15);
    cursor: pointer;
    width: 100%;
    height: auto;
    overflow: auto;
  }
  
  
  .t2, .t5 {
    text-align: center;
  }
  
  .t3 .t1, .t5, .t2 {
    border: 1px solid #ddd;
  }
  
  .t3 .t1:nth-child(even){
    background-color: #f3f3f3;
  }
  
  @media screen and (max-width: 550px) {
  body {
    align-items: flex-start;
  }
  .table-style  {
    width: 100%;
    margin: 0px;
    font-size: 10px;
  }
  }
  .t2{
    width: 500px;
  }
  
  .btn-danger-bt{
    background-color: #F08231 !important;
  }
  
  .btn-primary-bt{
    background-color: #6d9401 !important;
  }
  .fl-table {
    border-radius: 5px;
    font-size: 16px;
    font-weight: normal;
    border: none;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    background-color: white;
  }
  
  .fl-table td, .fl-table th {
    text-align: center;
    padding: 8px;
  }
  
  .fl-table td {
    border-right: 1px solid #f8f8f8;
    font-size: 13px;
  }
  .details{
    color: #6d9401;
    text-decoration: underline;
  }
  .content-wrapper{
    background-color: white !important;
  }

  .page-appro{
    margin-top: 2%;
  }
  
  
  /* Responsive
  
  @media (max-width: 767px) {
    .fl-table {
        display: block;
        width: 100%;
    }
    .table-wrapper:before{
        content: "Scroll horizontally >";
        display: block;
        text-align: right;
        font-size: 11px;
        color: white;
        padding: 0 0 10px;
    }
    .fl-table thead, .fl-table tbody, .fl-table thead th {
        display: block;
    }
    .fl-table thead th:last-child{
        border-bottom: none;
    }
    .fl-table thead {
        float: left;
    }
    .fl-table tbody {
        width: auto;
        position: relative;
        overflow-x: auto;
    }
    .fl-table td, .fl-table th {
        padding: 20px .625em .625em .625em;
        height: 60px;
        vertical-align: middle;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        width: 120px;
        font-size: 13px;
        text-overflow: ellipsis;
    }
    .fl-table thead th {
        text-align: left;
        border-bottom: 1px solid #f7f7f9;
    }
    .fl-table tbody tr {
        display: table-cell;
    }
    .fl-table tbody tr:nth-child(odd) {
        background: none;
    }
    .fl-table tr:nth-child(even) {
        background: transparent;
    }
    .fl-table tr td:nth-child(odd) {
        background: #F8F8F8;
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tr td:nth-child(even) {
        border-right: 1px solid #E6E4E4;
    }
    .fl-table tbody td {
        display: block;
        text-align: center;
    }
  } */